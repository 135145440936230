import { defineStore } from 'pinia'
import {
  CurrentDrupalUserFragment,
  CurrentDrupalUserLocalTaskFragment,
} from '#graphql-operations'
import { DrupalLinkProps } from '~/types/DrupalLink'

const STORAGE_KEY = '_phs_user_history_items'

/**
 * Names of the routes which the frontend implements.
 */
const FRONTEND_ROUTE_NAMES = [
  'pharmasuisse_profile.administration',
  'pharmasuisse_profile.contact_data',
  'pharmasuisse_profile.order_history',
  'pharmasuisse_user.pharmacies_overview',
  'entity.user.canonical',
]

type UserHistoryItem = {
  id: string
  name: string
  readableName: string
  isPharmacy: boolean
}

export enum DrupalRole {
  AffiliatedPharmacy = 'affiliated_pharmacy',
  NonAffiliatedPharmacy = 'non_affiliated_pharmacy',
  IndividualMember = 'individual_member',
  NonMember = 'non_member',
}

export type UserProfileLink = {
  label: string
  linkProps: DrupalLinkProps
  routeName?: string
  id: string
  text?: string
}

async function doLogout() {
  const token = await $fetch('/session/token/logout')
  await $fetch('/user/logout', {
    method: 'GET',
    query: {
      token,
    },
  })
}

/**
 * Get the description for the route.
 */
function getDescription(routeName: string, $texts: any): string | undefined {
  switch (routeName) {
    case 'pharmasuisse_profile.administration':
      return $texts(
        'profile.descriptionAdministration',
        'Benutzerkonto, E-Mail, Passwort, Verträge',
      )
    case 'view.commerce_file_my_files.page':
      return $texts('profile.descriptionFiles', 'Lizenzen, Handbücher')
    case 'pharmasuisse_profile.contact_data':
      return $texts(
        'profile.descriptionContactData',
        'Adresse, Telefonnummer, Kontaktdaten, Postfach, Sprache',
      )
    case 'pharmasuisse_pharmacy.pharmacy_general_form':
      return $texts(
        'profile.descriptionPharmacyGeneral',
        'Kontaktdaten, Telefonnummern, E-Mail Adressen',
      )
    case 'pharmasuisse_pharmacy.pharmacy_opening_hours_form':
      return $texts(
        'profile.descriptionPharmacyOpeningHours',
        'Reguläre Öffnungszeiten, Notfalldienst, Spezielle Öffnungszeiten',
      )
    case 'pharmasuisse_pharmacy.pharmacy_locations_form':
      return $texts(
        'profile.descriptionPharmacyLocations',
        'Addressen, Anfahrt, Koordinaten',
      )
    case 'pharmasuisse_profile.order_history':
      return $texts('profile.descriptionOrderHistory', 'Orders')
  }
}

/**
 * Determine if the given Drupal route name is rendered by the frontend app.
 */
function isFrontendRouted(routeName: string) {
  return FRONTEND_ROUTE_NAMES.includes(routeName)
}

/**
 * Map a Drupal local tasks link to a user link.
 */
function mapLocalTaskToProfileLink(
  v: CurrentDrupalUserLocalTaskFragment,
  $texts: any,
): UserProfileLink {
  const routeName = v.url && 'routeName' in v.url ? v.url.routeName : undefined
  return {
    label: v.title,
    linkProps: {
      to: v.url.path!,
      isDrupalRouted: routeName ? !isFrontendRouted(routeName) : true,
      openInSameWindow: true,
    },
    id: routeName || v.url.path!,
    routeName,
    text: routeName ? getDescription(routeName, $texts) : undefined,
  }
}

interface UserState {
  /**
   * Indicator if the state has already been fetched.
   */
  fetched: boolean

  /**
   * The ID of the user.
   */
  id: string

  /**
   * The user name (account name).
   */
  name: string

  /**
   * The user-readable name, e.g. name of the company or name of the person.
   */
  readableName: string

  /**
   * If the user can access the administration toolbar.
   */
  accessToolbar: boolean

  /**
   * If the user has a navision profile.
   */
  hasNavisionProfile: boolean

  /**
   * The ID of the pharmacy the user manages.
   */
  pharmacyId: string

  /**
   * Array of roles the user has.
   */
  roles: string[]

  /**
   * A list of other user names that have previously logged in.
   */
  userNameHistory: UserHistoryItem[]

  /**
   * If the user is currently masquerading.
   */
  isMasquerading: boolean

  /**
   * Whether the user has accepted the terms of service.
   */
  termsOfServiceAccepted: boolean

  localTasks: CurrentDrupalUserLocalTaskFragment[]
}

export const useDrupalUser = defineStore({
  id: 'drupalUser',
  state: (): UserState => {
    return {
      accessToolbar: false,
      id: '',
      name: '',
      readableName: '',
      fetched: false,
      hasNavisionProfile: false,
      pharmacyId: '',
      userNameHistory: [],
      roles: [],
      isMasquerading: false,
      localTasks: [],
      termsOfServiceAccepted: false,
    }
  },
  actions: {
    /**
     * Set the state from the drupalUser query.
     */
    set(data: CurrentDrupalUserFragment) {
      this.accessToolbar = !!data.canAccessToolbar
      this.id = data.id || ''
      this.name = data.name || ''
      this.fetched = true
      this.hasNavisionProfile = !!data.navision?.id
      this.pharmacyId = data.pharmacy?.id || ''
      this.readableName = data.label || data.name || data.id || ''
      this.roles = data.roles || []
      this.termsOfServiceAccepted = !!data.termsOfServiceAccepted
      this.localTasks =
        data.url && 'localTasks' in data.url ? data.url.localTasks : []
    },

    setIsMasquerading(isMasquerading = false) {
      this.isMasquerading = isMasquerading
    },

    setFetched() {
      this.fetched = true
    },

    /**
     * We redirect after fetching to logout.
     */
    async logout(destination?: string) {
      try {
        await doLogout()
      } catch (e) {
        // eslint-disable-next-line no-console -- Logging error.
        console.log('Error logging out', e)
      }

      if (process.client && destination !== undefined) {
        // Redirect to the front page.
        window.location.href = destination
      }
    },

    /**
     * Restore user name history.
     */
    initUserNameHistory() {
      // This only happens on client side.
      if (process.client) {
        try {
          // Get the existing stored history from the browser.
          const data = window.localStorage.getItem(STORAGE_KEY)

          // Try to restore the history.
          if (data) {
            const stored = JSON.parse(data) as UserHistoryItem[]
            if (stored && Array.isArray(stored)) {
              this.userNameHistory = stored.slice(-5)
            }
          }

          // Check if we're currently logged in and have all data needed to add
          // a history entry.
          if (!this.id || !this.name) {
            return
          }

          // Check if the current user is already in the history.
          const existing = this.userNameHistory.find((v) => v.id === this.id)
          if (existing) {
            return
          }

          // Add the user to the history.
          this.userNameHistory.push({
            id: this.id,
            name: this.name,
            readableName: this.readableName,
            isPharmacy:
              this.isAffiliatedPharmacy || this.isNonAffiliatedPharmacy,
          })

          // Store history in local storage.
          this.persistUserHistory()
        } catch (_e) {}
      }
    },

    /**
     * Remove a user from the history.
     */
    removeUserFromHistory(id: string) {
      this.userNameHistory = this.userNameHistory.filter((v) => v.id !== id)
      this.persistUserHistory()
    },

    persistUserHistory() {
      window.localStorage.setItem(
        STORAGE_KEY,
        JSON.stringify(this.userNameHistory),
      )
    },
  },
  getters: {
    /**
     * Indicates if we are in a logged in state.
     */
    isLoggedIn: (state) => !!state.id,

    /**
     * User is not a member (Nichtmitglied).
     */
    isNonMember: (state) => state.roles.includes(DrupalRole.NonMember),

    /**
     * User is an individual member (Einzelmitglied).
     */
    isIndividualMember: (state) =>
      state.roles.includes(DrupalRole.IndividualMember),

    /**
     * User is an affiliated pharmacy (Angeschlossene Apotheke).
     */
    isAffiliatedPharmacy: (state) =>
      state.roles.includes(DrupalRole.AffiliatedPharmacy),

    /**
     * User is a non-affiliated pharmacy (Nicht angeschlossene Apotheke).
     */
    isNonAffiliatedPharmacy: (state) =>
      state.roles.includes(DrupalRole.NonAffiliatedPharmacy),

    /**
     * The primary role of the user.
     */
    primaryRole() {
      if (this.isAffiliatedPharmacy) {
        return DrupalRole.AffiliatedPharmacy
      } else if (this.isNonAffiliatedPharmacy) {
        return DrupalRole.NonAffiliatedPharmacy
      } else if (this.isIndividualMember) {
        return DrupalRole.IndividualMember
      } else if (this.isNonMember) {
        return DrupalRole.NonMember
      }
    },

    /**
     * Return the profile links for the current user or the provided user.
     */
    profileLinks: (state) => {
      return (userOverride?: CurrentDrupalUserFragment): UserProfileLink[] => {
        const { $texts } = useNuxtApp()

        // Use the local tasks from the provided user override object.
        const tasks =
          userOverride?.url && 'localTasks' in userOverride.url
            ? userOverride.url.localTasks
            : state.localTasks

        // Build the user links.
        return tasks
          .filter(
            (v) =>
              'routeName' in v.url &&
              v.url.routeName !== 'entity.user.edit_form',
          )
          .map((v) => mapLocalTaskToProfileLink(v, $texts))
      }
    },

    canUseParagraphsBuilder: (state) => state.accessToolbar,
  },
})
